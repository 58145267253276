<template>
  <ul>
    <li v-for="(item, dx) in list" :key="dx">
      <router-link v-if="!!item.to && item.to.indexOf('http') == -1" :to="item.to">{{ item.text }} </router-link>
      <a v-if="!!item.to && item.to.indexOf('http') > -1" target="_blank" :href="item.to">{{ item.text }} </a>
      <a v-if="!!item.href" target="_blank" :href="item.href">{{ item.text }} </a>
    </li>
  </ul>
</template>

<script>
/* eslint-disable */
export default {
  props: {
    list: Array,
  },
}
</script>

<style scoped lang="scss">
@import '@/styles/settings.scss';
ul {
  margin: 0;
  margin-bottom: 22px;
  padding: 0;
}
li {
  margin-bottom: 8px;
  list-style-type: none;
  font-family: $open-sans;
  font-size: 14px;
  letter-spacing: 0.5px;
  line-height: 19px;
  a {
    display: inline-block;
    color: #fff;
  }
  &:first-of-type {
    a {
      color: $secondary-color;
      font-weight: 700;
      letter-spacing: 3.5px;
      text-transform: uppercase;
    }
  }
  &:last-of-type {
    margin-bottom: 0;
  }
}
</style>
